const config = {
  ENVIRONMENT_NAME: 'staging',
  forwoodId: {
    URL: 'https://id.staging.vale.forwoodsafety.com',
    APP_CLIENT_ID: '476hbo52patobk53uu97ma67f9',
    APIGATEWAY: 'https://mkpezi3wre.execute-api.us-east-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://0gj83h7y14.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    ENVIRONMENT_NAME: 'staging',
    VERSION: 'verification-container-1.8.0',
    HOSTNAME: 'https://verification.staging.vale.forwoodsafety.com',
    CLIENT: 'vale',
    VERIFICATIONS_ACCESS_ROLE: 'VerificationsAccess',
    MANAGER_VERIFICATION_LIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/verification-list',
    PERFORM_MANAGER_VERIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/mrm-overview-ccps',
    MANAGER_CONTROL_CHECKLIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/ccps-list',
    CREATE_MANAGER_CONTROL_CHECKLIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/node/add/ccps',
    MANAGER_CONTROL_CHECKLIST_APPROVALS_URL: 'https://iframe.staging.vale.forwoodsafety.com/approval-report/ccps',
    MANAGER_CONTROL_CHECKLIST_APPROVALS_SETTING_URL: 'https://iframe.staging.vale.forwoodsafety.com/ccps_approval_settings',
    PERFORM_SUPERVISOR_VERIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/crc',
    PERFORM_SUPERVISOR_TASK_BASED_VERIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/tbccfv',
    SUPERVISOR_VERIFICATION_LIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/ccsi-verification-list',
    PERFORM_OPERATOR_VERIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/ccc',
    PERFORM_OPERATOR_TASK_BASED_VERIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/tbccc',
    CRM_HOMEPAGE_URL: 'https://iframe.staging.vale.forwoodsafety.com/?v4_app=verifications',
    CREATE_DAILY_NOTIFICATION_URL: 'https://iframe.staging.vale.forwoodsafety.com/node/add/daily-notification',
    DAILY_NOTIFICATION_LIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/dn-list',
    VERIFICATION_SCHEDULE_URL: 'https://iframe.staging.vale.forwoodsafety.com/verification_scheduler',
    VERIFICATION_SCHEDULE_MANAGEMENT_URL: 'https://iframe.staging.vale.forwoodsafety.com/verification_scheduler_management',
    REPORT_SUBMISSION_TOTALS_URL: 'https://iframe.staging.vale.forwoodsafety.com/mrm-statistics/ccps/statistics/submission-totals',
    REPORT_STANDARD_REPORT_URL: 'https://iframe.staging.vale.forwoodsafety.com/mat_risk_standard_report',
    REPORT_VERIFICATION_SCHEDULER_REPORTS_URL: 'https://iframe.staging.vale.forwoodsafety.com/verification_scheduler_reports',
    REPORT_ACTION_PLAN_STATUS_URL: 'https://iframe.staging.vale.forwoodsafety.com/action-plan-status',
    REPORT_VERIFICATION_PARTICIPATION_SUMMARY_URL: 'https://iframe.staging.vale.forwoodsafety.com/verification-participation-summary',
    SUPERVISOR_OPERATOR_RISK_TB_CHECKLIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/crc-list',
    CREATE_SUPERVISOR_OPERATOR_TB_CHECKLIST_URL: 'https://iframe.staging.vale.forwoodsafety.com/node/add/crc?type=task_based',
    IFRAME_ORIGIN_URL: 'https://iframe.staging.vale.forwoodsafety.com'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
